<!-- 辅助会话设置 -->
<template>
  <div class="support-container common-padding">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 店铺名-->
    <div class="detailTest-detail">
      <div class="detail-title">
        <!-- 店铺名称: {{ shopDetail.shopName || "--" }} -->
        店铺名称: --
      </div>
    </div>
    <!-- 表单 -->
    <el-form ref="form" :model="form" class="form" label-width="20%">
      <el-form-item label="会话开始">
        <el-checkbox label="发送欢迎语" name="type"></el-checkbox>
        <span class="label">文字</span>
        <el-input
          class="common-screen-input_50"
          type="textarea"
          v-model="form.name"
          :autosize="{ minRows: 6 }"
        >
        </el-input>
        <!-- 图片 -->
        <div class="upload-userIcon">
          <span class="label">图片</span>
          <el-button
            style="margin: 0"
            class="common-screen-btn"
            type="primary"
            icon="el-icon-plus"
            >上传图片
          </el-button>
          <input
            class="common-upload-input"
            @input="chooseFile"
            type="file"
            style=""
          />
          <i class="el-icon-close" @click="handleRemove"></i>
          <!-- <img style="height:100%;width:100%;" v-if="true" src=""/> -->
        </div>
      </el-form-item>
      <el-form-item label="会话过程">
        <el-checkbox
          label="客服号长时间未回复，则自动发送以下话术"
          name="type"
        ></el-checkbox>
        <span class="send-time"
          >会话停止&nbsp;
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange"
            :min="0"
          ></el-input-number
          >&nbsp;秒时发送
        </span>
        <span class="label2">文字</span>
        <el-input
          class="common-screen-input_50"
          type="textarea"
          v-model="form.name"
          :autosize="{ minRows: 6 }"
        >
        </el-input>
        <!-- 图片 -->
        <div class="upload-userIcon">
          <span class="label">图片</span>
          <el-button
            style="margin: 0"
            class="common-screen-btn"
            type="primary"
            icon="el-icon-plus"
            >上传图片
          </el-button>
          <input class="common-upload-input" @input="chooseFile" type="file" />
          <i class="el-icon-close" @click="handleRemove"></i>
          <!-- <img style="height:100%;width:100%;" v-if="true" src=""/> -->
        </div>
      </el-form-item>
      <el-form-item>
        <el-checkbox
          label="顾客长时间未回复，则自动发送以下话术"
          name="type"
        ></el-checkbox>
        <span class="send-time"
          >会话停止&nbsp;
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange"
            :min="0"
          ></el-input-number
          >&nbsp;秒时发送
        </span>
        <span class="label2">文字</span>
        <el-input
          class="common-screen-input_50"
          type="textarea"
          v-model="form.name"
          :autosize="{ minRows: 6 }"
        >
        </el-input>
        <!-- 图片 -->
        <div class="upload-userIcon">
          <span class="label">图片</span>
          <el-button
            style="margin: 0"
            class="common-screen-btn"
            type="primary"
            icon="el-icon-plus"
            >上传图片
          </el-button>
          <input class="common-upload-input" @input="chooseFile" type="file" />
          <i class="el-icon-close" @click="handleRemove"></i>
          <!-- <img style="height:100%;width:100%;" v-if="true" src=""/> -->
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="onSubmit">确定</el-button>
        <el-button
          @click="
            () => {
              $router.go(-1);
            }
          "
          size="mini"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";

export default {
  name: "support",
  components: {
    Breadcrumb
  },
  props: {},
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "知识库", isLink: false },
        { title: "自动回复", isLink: true, url: "/library/auto-reply-list" },
        { title: "辅助会话设置", isLink: false }
      ],
      form: {
        name: ""
      },
      num: 0 //nputNumber 计数器
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleChange(value) {
      console.log(value);
    },
    // 删除上传图片
    handleRemove(file) {
      console.log(file);
    },
    // 上传图片
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (
        format != "bmp" &&
        format != "jpg" &&
        format != "png" &&
        format != "gif" &&
        format != "jpeg"
      ) {
        this.$message.error("仅支持bmp、png、jpg、jpeg、gif格式文件");
        e.target.value = "";
        return;
      }
      // let res = (await upload({ file: file })).data;
      // await changeIcon({ logo: res.filename });
      e.target.value = "";
      // this.userInfo.icon = res.filename;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.support-container {
  color: #606266;
  .detailTest-detail {
    display: flex;
    padding: 10px 10px 24px;
    align-items: center;
    color: #000000a6;
    .detail-title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .form {
    /deep/.el-form-item__content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      box-sizing: border-box;
      width: 60%;
      .common-screen-input_50 {
        width: 65% !important;
      }
    }
    .send-time {
      width: 66%;
      text-align: left;
      margin-bottom: 5px;
    }
    .el-checkbox {
      // float: left;
      margin-right: 10px;
    }
    .el-textarea {
      // float: left;
    }
    .label {
      // float: left;
      margin-right: 16px;
    }
    .label2 {
      margin-right: 16px;
      margin-left: 104px;
    }
    .upload-userIcon {
      position: relative;
      margin-left: 104px;
      text-align: left;
      width: 100px;
      .el-icon-close {
        position: absolute;
        top: 118px;
        left: 118px;
        font-size: 25px;
      }
      /deep/.common-screen-btn {
        margin: 0px;
        width: 100px;
        height: 100px;
        background-color: #fafafa;
        color: #575757;
        border: 1px dashed #d9d9d9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.common-upload-input {
  width: 100px;
}
</style>
